* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  font-size: 14px;
  font-weight: 400;
  font-family: NotoSans, Arial, sans-serif;
  line-height: 1.4;
  color: #000;
  background-color: #EEEEEE;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
header {
  width: 100%;
  margin-bottom: 10px;
}
header .header__burger {
  display: none;
  position: relative;
  width: 25px;
  height: 16px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
header .header__nav {
  width: 100%;
}
header .header__nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}
header .header__nav ul li {
  width: 19%;
  background-color: #DC1F26;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 800;
  padding: 6px 10px;
}
main {
  flex: 1;
  background-color: #fff;
  padding: 10px 50px 20px;
}
.content > * {
  margin-bottom: 10px;
}
.content h1,
.content h2,
.content h3 {
  text-align: center;
  font-weight: 700;
}
.content h1 {
  font-size: 18px;
  color: #DC1F26;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
}
.content h2 {
  font-size: 16px;
}
.content h3 {
  font-size: 14px;
}
.content img.full {
  width: 100%;
}
.content img.center {
  display: block;
  width: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content ol,
.content ul {
  list-style-position: outside;
  padding-left: 15px;
}
.content blockquote {
  position: relative;
  background-color: #DC1F26;
  color: #fff;
  padding: 6px 80px;
  font-style: italic;
  border-radius: 50px;
}
.content blockquote:before {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../img/quote-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.content table {
  width: 100%;
  border-collapse: collapse;
}
.content table td {
  background-color: #DC1F26;
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 5px 10px;
  border: 4px solid #fff;
}
footer {
  width: 100%;
  background-color: #DC1F26;
  margin-top: 10px;
}
footer .footer__copyright {
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 10px;
}
@media screen and (max-width: 768px) {
  header {
    background-color: #DC1F26;
    padding: 8px 10px;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(33deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-33deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    top: -20px;
    left: 50px;
    background-color: #DC1F26;
    padding: 5px;
    max-width: 200px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  header .header__nav.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    justify-content: flex-start;
  }
  header .header__nav ul li {
    margin-bottom: 10px;
    width: auto;
    font-size: 16px;
  }
  main {
    padding: 10px 15px 20px;
  }
  .content blockquote {
    padding-right: 20px;
  }
  footer .footer__copyright span {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .content table td {
    min-width: auto;
    padding: 8px 15px;
  }
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
