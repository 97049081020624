@media screen and (max-width: 768px) {
  header {
    background-color: @red;
    padding: 8px 10px;

    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(33deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-33deg);
              transform-origin: left;
            }
          }
        }
      }

      &__nav {
        position: absolute;
        top: -20px;
        left: 50px;
        background-color: @red;
        padding: 5px;
        max-width: 200px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
          top: 10px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          justify-content: flex-start;

          li {
            margin-bottom: 10px;
            width: auto;
            font-size: 16px;
          }
        }
      }
    }
  }

  main {
    padding: 10px 15px 20px;
  }

  .content {
    blockquote {
      padding-right: 20px;
    }
  }

  footer {
    .footer {
      &__copyright {
        span {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .content {
    table {
      td {
        min-width: auto;
        padding: 8px 15px;
      }
    }
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}