@red: #DC1F26;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  font-size: 14px;
  font-weight: 400;
  font-family: NotoSans, Arial, sans-serif;
  line-height: 1.4;
  color: #000;
  background-color: #EEEEEE;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

header {
  width: 100%;
  margin-bottom: 10px;

  .header {
    &__burger {
      display: none;
      position: relative;
      width: 25px;
      height: 16px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__nav {
      width: 100%;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        
        li {
          width: 19%;
          background-color: @red;
          text-align: center;
          color: #fff;
          font-size: 20px;
          font-weight: 800;
          padding: 6px 10px;
        }
      }
    }
  }
}

main {
  flex: 1;
  background-color: #fff;
  padding: 10px 50px 20px;
}

.content {
  & > * {
    margin-bottom: 10px;
  }
  h1, h2, h3 {
    text-align: center;
    font-weight: 700;
  }
  h1 {
    font-size: 18px;
    color: @red;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.center {
      display: block;
      width: auto;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
  }
  ol, ul {
    list-style-position: outside;
    padding-left: 15px;
  }
  blockquote {
    position: relative;
    background-color: @red;
    color: #fff;
    padding: 6px 80px;
    font-style: italic;
    border-radius: 50px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../img/quote-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      background-color: @red;
      color: #fff;
      text-align: center;
      font-weight: 700;
      padding: 5px 10px;
      border: 4px solid #fff;
    }
  }
}

footer {
  width: 100%;
  background-color: @red;
  margin-top: 10px;

  .footer {
    &__copyright {
      color: #fff;
      text-align: center;
      font-weight: 700;
      padding: 10px;
    }
  }
}

@import "media";